.container {
  width: fit-content;
  display: inline-flex;
  margin-bottom: 40px;
}

.title {
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 19px;
  line-height: 114%;
}

.location {
  color: #53c3d0;
  font-size: 12px;
  line-height: 122%;
}

.description-container {
  margin: 15px 85px 15px 30px;
  display: flex;
  flex-direction: column;
}
