.line {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}

.item {
  flex: 1;
}

.item > div {
  display: inline-flex;
}

.name {
  margin: 3px 15px;
}
