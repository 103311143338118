.navbar {
  height: 64px;
  background: #ffffff;
  box-shadow: 4px 8px 40px rgba(227, 230, 234, 0.3);
  display: flex;
}

.navbar > * {
  margin-top: auto;
  margin-bottom: auto;
}

.logo {
  margin-left: 82px;
  margin-right: 295px;
}

.nav-container {
  margin-right: 308px;
  margin-left: 80px;
  display: flex;
}

.nav-container > * {
  margin-right: 30px;
  margin-bottom: auto;
  margin-top: auto;
}

.sign-container {
  margin-right: 80px;
  display: flex;
}

.sign-container > * {
  margin-bottom: auto;
  margin-top: auto;
}
