.datepicker {
  width: 80%;
  position: absolute;
  left: 5.34%;
  right: 93.24%;
  top: 46%;
  bottom: 18%;

  font-size: 13px;
  line-height: 142%;

  padding: 0;
  color: #022b54;
  border: 0;
}

.datepicker a span:last-of-type {
  display: none;
}
