.field {
  position: relative;
  background: #ffffff;
  border: 1px solid #e8eff5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 281px;
  height: 50px;
}

.field:hover {
  border: 2px solid #d1eff2;
}

.field:focus {
  border: 2px solid #a3dfe6;
}

.field input,
.field select {
  position: absolute;
  left: 5.34%;
  right: 93.24%;
  top: 46%;
  bottom: 18%;

  font-size: 13px;
  line-height: 142%;

  color: #022b54;
  border: 0;
}

.title {
  position: absolute;
  height: 13px;
  left: 15px;
  top: 8px;

  font-size: 11px;
  line-height: 122%;

  color: #53c3d0;
}

.placeholder {
  position: absolute;
  left: 5.34%;
  right: 72.6%;
  top: 46%;
  bottom: 18%;

  font-size: 13px;
  line-height: 142%;

  color: #022b54;
  opacity: 0.3;
}

.field:hover .placeholder {
  top: 44%;
  bottom: 20%;
}
