.container {
  display: flex;
  flex-direction: column;
}

.line {
  background: #f7f7f7;
  border-radius: 2px;
  height: 17px;
  margin-bottom: 4px;
}

.line:nth-of-type(1) {
  width: 74px;
}
.line:nth-of-type(2) {
  width: 98px;
  height: 22px;
}
.line:nth-of-type(3) {
  width: 45px;
  margin-bottom: 17px;
}
