.text1 {
  font-weight: 600;
  color: #53c3d0;
  font-size: 9px;
  line-height: 122%;

  margin-bottom: 1px;
  margin-top: 40px;
  text-transform: uppercase;
}

.text2 {
  margin-bottom: 30px;
  color: #022b54;
  font-weight: 300;
  font-size: 28px;
  line-height: 122%;
}
