.toggle {
  font-size: 16px;
  line-height: 154%;
  font-weight: 600;
  opacity: 0.7;
  cursor: pointer;
  background-color: inherit;
}

.container {
  margin: 52px auto;
  color: #022b54;
  text-align: center;
  width: 870px;
}

.collapsed .multiline-ellipsis {
  text-overflow: ellipsis;
  overflow-y: hidden;
  max-height: 100px;
}

.multiline-ellipsis {
  color: #505051;
  overflow: hidden;
  position: relative;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.multiline-ellipsis:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}
.multiline-ellipsis:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
