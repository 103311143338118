.details {
  display: inline-flex;
  height: 24px;
  position: relative;
  color: #022b54;
  opacity: 0.3;
  font-size: 12px;
  line-height: 122%;
  margin-bottom: 40px;
}

.details div {
  margin: 4.5px 0;
}

.details svg {
  margin-left: 20px;
}

.details svg:first-child {
  margin-left: 0;
}
