.picture {
  height: 208px;
  width: 390px;
  border-radius: 2px;
  overflow: hidden;
}
.picture img {
  height: auto;
  width: 100%;
}
