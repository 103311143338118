.container {
  display: flex;
  flex-direction: row;
  height: 792px;
  overflow: auto;
  white-space: nowrap;
}

.container img {
  height: 100%;
  width: auto;
  margin-left: 10px;
}
