.nav {
  height: 18px;

  font-size: 13px;
  line-height: 142%;

  color: #022b54;
}

.nav:hover {
  color: #53c3d0;
}

.nav:active {
  color: #53c3d0;
  text-decoration: underline;
}

.nav.active {
  color: #53c3d0;
  text-decoration: underline;
}
