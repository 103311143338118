.container {
  margin-bottom: 52px;
  margin-top: 66px;
  text-align: center;
}

.title {
  font-weight: bold;
  color: #011b35;
  font-size: 40px;
  line-height: 120%;
}

.location {
  font-size: 16px;
  line-height: 122%;
  color: #53c3d0;
}
