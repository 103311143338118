.btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 8px 25px;

  width: 110px;
  height: 36px;

  font-weight: 600;
  font-size: 14px;
  line-height: 142%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #53c3d0;
  border-radius: 2px;
  border: 2px solid #53c3d0;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #fff;

  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 10px;
}

.btn.large {
  width: 211px;
  height: 36px;
}

.btn.xlarge {
  width: 273px;
  height: 36px;
}

.btn:hover {
  color: #1c5d9f;
  border: 2px solid #1c5d9f;
}

.btn:active {
  color: #022b54;
  border: 2px solid #022b54;
}
