.searchbar {
  height: 64px;
  background: #ffffff;
  box-shadow: 4px 8px 40px rgba(227, 230, 234, 0.3);
  display: flex;
}

.where {
  margin-left: 80px;
  width: 346px;
}

.when {
  width: 283px;
}

.who {
  width: 175px;
}

.order {
  width: 185px;
}

.coupon {
  width: 281px;
  margin-right: 80px;
  margin-left: 10px;
}
