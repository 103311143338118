body {
  font-family: Source Sans Pro;
  letter-spacing: -0.01em;
}

*:focus {
  outline: none;
}

.hide {
  display: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit !important;
  text-decoration: none !important;
}

.rs-picker-range {
  padding: 0 !important;
}

.rs-picker-toggle {
  padding: 0 !important;
  background: none !important;
  border: none !important;
}

.rs-picker-toggle-placeholder {
  color: #022b54;
  opacity: 0.3;
}
.rs-picker-toggle {
  width: 100%;
}

.rs-picker-toggle-clean {
  top: 0 !important;
}

.rs-picker-toggle-value {
  font-size: 13px;
  line-height: 142%;

  color: #022b54 !important;
}
