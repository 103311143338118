.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 235px;
  width: fit-content;
}

.container button {
  margin: auto;
}

.satellite {
  display: block;
  margin: auto;
  margin-bottom: 40px;
}

.text {
  margin-bottom: 38px;
  text-align: center;
}
