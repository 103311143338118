.scroll-loader {
  margin: 55px auto;
  display: block;
  width: 182px;
  height: 30px;
  background: #f7f7f7;
  border-radius: 2px;
  font-size: 16px;
  line-height: 122%;
  color: #b3b3b3;
  cursor: auto;
}

.scroll-loader-hide {
  margin: 0 auto;
  cursor: auto;
  display: block;
  width: 1px;
  height: 1px;
  background: #ffffff;
  font-size: 1px;
  color: #ffffff;
}
