.container {
  margin: auto;
  width: 860px;
}

.title {
  font-size: 26px;
  line-height: 146%;
  color: #022b54;
  margin-bottom: 50px;
}

.more {
  font-weight: 600;
  font-size: 18px;
  line-height: 144%;
  margin-top: 26px;
  margin-bottom: 30px;
  color: #022b54;
}
