.empty-card {
  height: 208px;
  width: 390px;
  background: #f7f7f7;
  border-radius: 2px;
}

.container {
  display: flex;
  margin-left: 319px;
}

.description-container {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.line {
  background: #f7f7f7;
  border-radius: 2px;
  height: 17px;
  margin-bottom: 4px;
}

.line:nth-of-type(1) {
  margin-top: 18px;
  width: 132px;
}

.line:nth-of-type(2) {
  width: 218px;
  height: 28px;
}
.line:nth-of-type(3) {
  width: 241px;
}
.line:nth-of-type(4) {
  background: #ffffff;
  height: 31px;
}
.line:nth-of-type(5) {
  width: 74px;
}
.line:nth-of-type(6) {
  width: 98px;
  height: 22px;
}
.line:nth-of-type(7) {
  width: 45px;
  margin-bottom: 17px;
}
