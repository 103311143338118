.season-price {
  height: 60px;
  display: inline-flex;
}

.price-tier {
  margin-right: 74px;
}
.price-tier:last-child {
  margin-right: 0;
}

.price {
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  color: #022b54;
}

.night {
  color: #022b54;
  opacity: 0.3;
  font-size: 12px;
  line-height: 142%;
}

.price-description {
  font-size: 12px;
  line-height: 142%;
  color: #022b54;
  opacity: 0.3;
  display: inline-flex;
}
